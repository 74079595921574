import "@total-typescript/ts-reset";
import React, { type ReactNode } from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom";
import AppRoot from "~/AppRoot";
import config from "~/temp/config";
import i18ninit from "~/foundation/Dictionary/i18n";
import { HelmetProvider } from "react-helmet-async";
// import reportWebVitals from "./reportWebVitals"
// import * as serviceWorker from "./serviceWorker"
import { preloadReady } from "~/foundation/ReactLoadable";

let renderFunction = (rootElement: HTMLElement, component: ReactNode) => {
	const root = ReactDOM.createRoot(rootElement);
	root.render(component);
};

let initLanguage = config.defaultLanguage;

/*
	SSR Data
	If we're running in a server-side rendering scenario,
	the server will provide JSON in the #__JSS_STATE__ element
	for us to acquire the initial state to run with on the client.

	This enables us to skip a network request to load up the layout data.
	We are emitting a quiescent script with JSON so that we can take advantage
	of JSON.parse()'s speed advantage over parsing full JS, and enable
	working without needing `unsafe-inline` in Content Security Policies.

	SSR is initiated from /server/server.js.
*/
let __JSS_STATE__: any = null; // eslint-disable-line
const ssrRawJson = document.getElementById("__JSS_STATE__");
if (ssrRawJson) {
	__JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
	// when React initializes from a SSR-based initial state, you need to render with `hydrateRoot` instead of `render`
	renderFunction = ReactDOM.hydrateRoot;

	// set i18n language SSR state language instead of static config default language
	initLanguage = __JSS_STATE__.sitecore.context.language;
}


const rootElement = document.getElementById("root");
if (!rootElement) {
	throw "Could not find root element";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const siteName = rootElement.dataset.siteName || config.devProject?.siteName;
if (!siteName) {
	throw "Could not find siteName"
}

/*
	App Rendering
*/
// initialize the dictionary, then render the app
// note: if not making a multlingual app, the dictionary init can be removed.

i18ninit(siteName, initLanguage, undefined)
	.then(() => preloadReady())
	.then(() => {

		renderFunction(
			rootElement,
			// StrictMode will make the page render twice at start, but only in dev environment
			<React.StrictMode>
				<HelmetProvider>
					<AppRoot
						path={window.location.pathname}
						Router={BrowserRouter}
						ssrState={__JSS_STATE__}
						siteName={siteName}
						routerContext={{}}
					/>
				</HelmetProvider>
			</React.StrictMode>
		);
	});